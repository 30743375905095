<template>
  <div class="page admin-main">
    <div class="admin-main__welcome">
      <div class="admin-main__title">
        <h3>Добро пожаловать, {{ userName }} {{ userLastName }}</h3>
        <p>Вы зашли в администраторский интерфейс портала</p>
      </div>
      <esmp-icon
        class="admin-main__picture"
        name="empty-favorites"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'Home',
  computed: {
    ...mapState('user', ['loggedInUser']),
    userName() {
      return this.loggedInUser?.name;
    },
    userLastName() {
      return this.loggedInUser?.lastName;
    },
  },
};
</script>

<style lang="scss">
.admin-main {
  &__welcome {
    width: 100%;
    position: relative;
    background-color: $color-white;
    min-height: 168px;
    padding: 32px;
    border-radius: 16px;
    overflow: hidden;
    @include for-size(tablet-portrait-down) {
      width: 100%;
    }
    @include for-size(phone-portrait-down) {
      min-height: 98px;
    }
  }

  &__title {
    width: 460px;
    @include for-size(phone-portrait-down) {
      max-width: 180px;
    }

    p {
      margin-top: 10px;
      font-size: 14px;
      line-height: 20px;
    }
  }

  &__picture {
    position: absolute;
    right: 0;
    bottom: 0;
    height: 168px;
    width: 251px;

    @include for-size(phone-portrait-down) {
      width: 120px;
      height: 98px;
      right: -10px;
    }
  }
}
</style>
